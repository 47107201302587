var __jsx = React.createElement;
import React from 'react';
var arrowRightIcon = "/static/slick/Icon-Arrow-right.svg";
import styled from 'styled-components';
var ArrowLeft = styled.img.withConfig({
  displayName: "ArrowIcon__ArrowLeft",
  componentId: "sc-ll4d0d-0"
})(["display:none;@media ", "{display:block;height:", ";width:", ";border-radius:50%;padding:0;cursor:pointer;z-index:1;transform:rotate(90deg);}"], function (_ref) {
  var theme = _ref.theme;
  return theme.responsiveQuery.desktop;
}, function (props) {
  return props.height;
}, function (props) {
  return props.width;
});
export var ArrowRight = styled(ArrowLeft).withConfig({
  displayName: "ArrowIcon__ArrowRight",
  componentId: "sc-ll4d0d-1"
})(["@media ", "{transform:rotate(-90deg);}"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.responsiveQuery.desktop;
});
var ArrowIcon = function ArrowIcon(_ref3) {
  var direction = _ref3.direction,
    width = _ref3.width,
    height = _ref3.height;
  return __jsx(React.Fragment, null, direction === 'left' && __jsx(ArrowLeft, {
    src: arrowRightIcon,
    width: width,
    height: height,
    alt: "scroll carousel left"
  }), direction === 'right' && __jsx(ArrowRight, {
    src: arrowRightIcon,
    width: width,
    height: height,
    alt: "scroll carousel right"
  }));
};
ArrowIcon.defaultProps = {
  width: '20px',
  height: '20px',
  direction: 'left'
};
export default ArrowIcon;