import React from "react";
var __jsx = React.createElement;
var Heart = "/static/icons/heartWithCircle.png";
var HeartRed = "/static/icons/heartWithCircleRed.png";
import styled from 'styled-components';
var HeartIcon = styled.img.withConfig({
  displayName: "HeartWithCircle__HeartIcon",
  componentId: "sc-1ymhvl3-0"
})(["width:28px;height:28px;"]);
var HeartWithCircle = function HeartWithCircle(_ref) {
  var isFilled = _ref.isFilled;
  return isFilled ? __jsx(HeartIcon, {
    src: HeartRed,
    alt: "favourite icon"
  }) : __jsx(HeartIcon, {
    src: Heart,
    alt: "not favourite icon"
  });
};
export default HeartWithCircle;