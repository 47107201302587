import styled, { css } from 'styled-components';
import { colours, fontSizes, customFonts, layoutSizes } from "../../../constants/css";
import { Button } from "../../_global";
var pebble = "/static/offers/pebble.svg"; // TODO: write a mixin for hover
export var CardContainer = styled.div.withConfig({
  displayName: "style__CardContainer",
  componentId: "sc-13i5y9z-0"
})(["position:relative;display:block;background-color:", ";border-radius:4px;", " flex:1;max-width:263px;min-width:", ";height:", ";height:", ";margin:1rem;transition:all .15s linear;@media only screen and (min-width:", "){height:", ";height:", ";margin:1rem;display:flex;flex-direction:column;justify-content:flex-end;", " &:hover{", "}}"], colours.qantasWhite, function (props) {
  return !props.noShadow && css(["box-shadow:0 2px 3px 0 rgba(0,0,0,0.2),0 10px 10px 0 rgba(0,0,0,0.05);"]);
}, function (props) {
  return props.wideView ? '302px' : '262px';
}, function (props) {
  return props.offerType === 'MALL' ? '288px' : '387px';
}, function (props) {
  return props.wideView ? 'auto' : 'inherit';
}, layoutSizes.desktopWidth, function (props) {
  return props.offerType === 'MALL' ? '285px' : '340px';
}, function (props) {
  return props.wideView ? 'auto' : 'inherit';
}, function (props) {
  return props.offerType === 'MCO' && "\n      justify-content: stretch;\n    ";
}, function (props) {
  return !props.noShadow && css(["box-shadow:0 2px 3px 0 rgba(45,45,45,0.02),rgba(45,45,45,0.02) 0px 2px 2px,rgba(49,49,49,0.05) 0px 4px 4px,rgba(42,42,42,0.05) 0px 8px 8px,rgba(32,32,32,0.05) 0px 16px 16px,rgba(49,49,49,0.05) 0px 13px 19px,rgba(35,35,35,0.05) 0px 10px 40px;transform:translate(0,-4px);"]);
});
export var OfferImage = styled.div.withConfig({
  displayName: "style__OfferImage",
  componentId: "sc-13i5y9z-1"
})(["height:145px;background:url(", ") 50% 0% no-repeat;background-size:cover;border-top-left-radius:4px;border-top-right-radius:4px;"], function (props) {
  return props.offerImg;
});
export var MerchantLogo = styled.div.withConfig({
  displayName: "style__MerchantLogo",
  componentId: "sc-13i5y9z-2"
})(["border-top-right-radius:4px;border-top-left-radius:4px;min-height:60px;background-image:url('", "');background-position:center;background-repeat:no-repeat;", ""], function (props) {
  return props.merchantLogo;
}, function (props) {
  return props.offerType === 'MALL' && "\n    background-size: contain;\n    height: 60px;\n    border-radius: 0;\n    margin-top: 20px;\n  ";
});
export var PointsPebble = styled.div.withConfig({
  displayName: "style__PointsPebble",
  componentId: "sc-13i5y9z-3"
})(["background:url(", ");background-repeat:no-repeat;width:80px;height:80px;line-height:1;text-align:center;position:absolute;top:35px;right:1rem;padding-top:1.5rem;font-family:", ";font-size:", ";> div{font-family:", ";font-size:", ";}"], pebble, customFonts.ciutadellaMed, fontSizes.large, customFonts.ciutadellaReg, fontSizes.small);
export var CardBody = styled.div.withConfig({
  displayName: "style__CardBody",
  componentId: "sc-13i5y9z-4"
})(["background-color:", ";padding:10px 10px 0;overflow:hidden;"], colours.qantasWhite);
export var CardCta = styled.div.withConfig({
  displayName: "style__CardCta",
  componentId: "sc-13i5y9z-5"
})(["margin:5px 6px;", " ", ""], function (props) {
  return props.offerType === 'MCO' && "\n    display: flex;\n    justify-content: center;\n  ";
}, function (props) {
  return props.offerType === 'MALL' && "\n    text-align: center;\n  ";
});
export var CtaButton = styled(Button).attrs({
  name: function name(props) {
    return props.hasName || props.merchant || null;
  }
}).withConfig({
  displayName: "style__CtaButton",
  componentId: "sc-13i5y9z-6"
})(["padding:0.65rem 1rem;", " ", " ", ""], function (props) {
  return props.offerType === 'MALL' && "\n    max-width: 80%;\n\n    img {\n      vertical-align: sub;\n      margin-right: 5px;\n      height: 16px;\n      display: inline-block;\n    }\n  ";
}, function (props) {
  return props.offerType === 'MCO' && "\n    width: 100%;\n\n    &:nth-child(2) {\n      margin-left: 0.5rem;\n    }\n\n    img {\n      vertical-align: sub;\n      margin-right: 5px;\n      height: 16px;\n      display: inline-block;\n    }\n  ";
}, function (props) {
  return props.isError && "\n    background-color: ".concat(colours.qantasWarningHover, ";\n    color: #000;\n\n    &:hover {\n      background-color: ").concat(colours.qantasWarningHover, ";\n      color: #000;\n      border-color: ").concat(colours.qantasWarningHover, ";\n    }\n  ");
});
export var CardTitle = styled.h2.withConfig({
  displayName: "style__CardTitle",
  componentId: "sc-13i5y9z-7"
})(["margin-bottom:0.75rem;font-size:1.5rem;font-weight:300;line-height:1.1;color:#373a3c;"]);
export var CardDescription = styled.p.withConfig({
  displayName: "style__CardDescription",
  componentId: "sc-13i5y9z-8"
})(["font-size:", ";line-height:1.2;"], fontSizes.base);
export var CardFooter = styled.footer.withConfig({
  displayName: "style__CardFooter",
  componentId: "sc-13i5y9z-9"
})(["display:flex;justify-content:space-between;padding:1rem;font-size:", ";align-items:center;span{text-transform:uppercase;font-family:", ";margin-top:-4px;}button{display:flex;color:", ";background:", ";padding:0;border:none;cursor:pointer;text-decoration:underline;margin-top:-4px;}"], fontSizes.xs, customFonts.ciutadellaMed, colours.qantasGrey, colours.qantasWhite);
export var CardInfo = styled.div.withConfig({
  displayName: "style__CardInfo",
  componentId: "sc-13i5y9z-10"
})(["display:block;opacity:", ";background-color:", ";position:absolute;top:0;left:0;right:0;border-radius:4px;z-index:", ";height:100%;transition:opacity .15s linear;overflow-y:scroll;"], function (props) {
  return props.isVisible ? '1' : '0';
}, colours.qantasWhite, function (props) {
  return props.isVisible ? '1' : '-1';
});
export var InfoHeader = styled.div.withConfig({
  displayName: "style__InfoHeader",
  componentId: "sc-13i5y9z-11"
})(["font-family:", ";width:100%;text-align:left;button{border:none;cursor:pointer;background-color:transparent;position:absolute;top:0.8rem;right:0.5rem;}"], customFonts.ciutadellaMed);
export var InfoHeading = styled.p.withConfig({
  displayName: "style__InfoHeading",
  componentId: "sc-13i5y9z-12"
})(["padding:1rem;margin:0;border-bottom:1px solid #d0d0d0;font-size:", ";"], fontSizes.large);
export var InfoBody = styled.div.withConfig({
  displayName: "style__InfoBody",
  componentId: "sc-13i5y9z-13"
})(["padding:0.5rem 1rem 1rem;max-height:100%;text-align:left;font-size:", ";margin:0;> div{padding-bottom:1rem;}"], fontSizes.small);
export var InfoSpecial = styled(InfoBody).withConfig({
  displayName: "style__InfoSpecial",
  componentId: "sc-13i5y9z-14"
})(["padding:0 1rem 0;"]);
export var InfoSubHeading = styled.p.withConfig({
  displayName: "style__InfoSubHeading",
  componentId: "sc-13i5y9z-15"
})(["font-size:", ";font-family:", ";padding:1rem 1rem 0;margin:0;"], fontSizes.small, customFonts.ciutadellaMed);
export var InfoButton = styled.button.withConfig({
  displayName: "style__InfoButton",
  componentId: "sc-13i5y9z-16"
})(["margin:", ";"], function (props) {
  return props.alignCenter ? '0' : 'auto';
});
export var CardDetail = styled.div.withConfig({
  displayName: "style__CardDetail",
  componentId: "sc-13i5y9z-17"
})(["display:flex;align-items:stretch;justify-content:space-between;height:60px;margin:1.25rem 0 2rem;> div{font-family:", ";font-size:", ";color:", ";display:flex;align-items:center;justify-content:space-between;text-align:center;flex-direction:column;width:50%;@media only screen and (min-width:", "){font-size:", ";}&:first-child{border-right:1px solid ", ";}", "}"], customFonts.ciutadellaMed, fontSizes.base, colours.qantasCharcoal, layoutSizes.desktopWidth, fontSizes.small, colours.qantasLightGrey, function (props) {
  return props.offerType === 'MCO' && "\n      &:last-child {\n        display: block;\n      }\n    ";
});
export var RedemptionDetails = styled.div.withConfig({
  displayName: "style__RedemptionDetails",
  componentId: "sc-13i5y9z-18"
})(["img{max-width:110px;margin:0.3rem auto;}"]);
export var PointsTerm = styled.div.withConfig({
  displayName: "style__PointsTerm",
  componentId: "sc-13i5y9z-19"
})(["font-family:", ";font-size:", ";"], customFonts.ciutadellaReg, fontSizes.small);
export var PointsEarn = styled.div.withConfig({
  displayName: "style__PointsEarn",
  componentId: "sc-13i5y9z-20"
})(["font-family:", ";font-size:1.625rem;> span:last-child{font-size:", ";margin-left:5px;}"], customFonts.ciutadellaMed, fontSizes.xs);
export var PrevPoints = styled.span.withConfig({
  displayName: "style__PrevPoints",
  componentId: "sc-13i5y9z-21"
})(["font-family:", ";font-size:", ";color:", ";text-decoration:line-through;margin-right:5px;"], customFonts.ciutadellaMed, fontSizes.base, colours.qantasRed);
export var MerchantTitle = styled.div.withConfig({
  displayName: "style__MerchantTitle",
  componentId: "sc-13i5y9z-22"
})(["font-size:", ";text-align:center;> div:last-child{font-family:", ";}"], fontSizes.xxl, customFonts.ciutadellaMed);
export var EarnContainer = styled.div.withConfig({
  displayName: "style__EarnContainer",
  componentId: "sc-13i5y9z-23"
})(["width:100%;"]);